import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import {
    LanguageService,
    provideLanguageService,
    SetAcceptLanguageHeaderInterceptor,
    TranslocoHttpLoader,
} from '@icp/angular/i18n';
import { DefaultIdpStorage, provideIdpServiceConfig } from '@icp/angular/idp';
import { provideStore } from '@ngrx/store';

import { rootFeature } from '../state';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideExperimentalZonelessChangeDetection(),
        provideHttpClient(
            withFetch(),
            withInterceptors([SetAcceptLanguageHeaderInterceptor(rootFeature.selectLanguage)]),
        ),
        provideAnimationsAsync(window.matchMedia('(prefers-reduced-motion: reduce)').matches ? 'noop' : 'animations'),
        provideRouter(appRoutes),
        provideStore({ [rootFeature.name]: rootFeature.reducer }),
        provideIdpServiceConfig({ serverUrl: '', clientId: null, storage: DefaultIdpStorage }),
        provideLanguageService({ selector: rootFeature.selectLanguage, loader: TranslocoHttpLoader }),
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [LanguageService],
            useFactory: (languageService: LanguageService) => () => languageService.initialize(),
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' } satisfies MatFormFieldDefaultOptions,
        },
    ],
};
