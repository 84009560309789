import { Route } from '@angular/router';

export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'session-expired' },
    { path: 'sign-in', loadComponent: () => import('./authorize/authorize.component') },
    { path: 'code-confirm', loadComponent: () => import('./code-confirm/code-confirm.component') },
    { path: 'reset-password', loadComponent: () => import('./reset-password/reset-password.component') },
    { path: 'session-expired', loadComponent: () => import('./session-expired/session-expired.component') },
    { path: 'register', loadComponent: () => import('./register/register.component') },
];
