import { Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterOutlet } from '@angular/router';
import { privacyUrl, termsUrl } from '@icp/angular/data';
import { IdpOauth2AuthorizeAction } from '@icp/interfaces';
import { TranslocoDirective } from '@jsverse/transloco';

import { AuthorizeStorageService } from './authorize-storage.service';

@Component({
    standalone: true,
    imports: [RouterOutlet, TranslocoDirective, MatButtonModule],
    selector: 'auth-root',
    styleUrl: './app.component.scss',
    templateUrl: './app.component.html',
})
export class AppComponent {
    protected readonly termsUrl = termsUrl;
    protected readonly privacyUrl = privacyUrl;
    loginConfig = inject(AuthorizeStorageService).loginConfig;
    isRegistering = computed(() => this.loginConfig()?.action === IdpOauth2AuthorizeAction.REGISTER);
    closeUrl = computed(() => this.loginConfig()?.client?.closeUrl);
}
