<ng-container *transloco="let t">
    <header>
        <img alt="Otzy" class="otzy-logo" src="/assets/logo-color.svg" />
        <h1 class="mat-headline-medium">{{ isRegistering() ? t('REGISTER_FOR_OTZY') : t('SIGN_IN_WITH_OTZY') }}</h1>
        @if (closeUrl()) {
            <a mat-stroked-button [href]="closeUrl()" class="close-button">{{ t('CLOSE') }}</a>
        }
    </header>
    <main class="content-container">
        <router-outlet />
    </main>
    <footer>
        <a [href]="termsUrl" target="_blank">{{ t('TERMS_AND_CONDITIONS') }}</a>
        <a [href]="privacyUrl" target="_blank">{{ t('PRIVACY_POLICY') }}</a>
    </footer>
</ng-container>
